import API from "@/plugins/servers/Api.js";
//import { Reward } from '@/models/Reward';

export const fetch_rewards = async ({ commit }) => {
  try {
    let [res] = await API.get("/rewards/");
    if (res._status === 200) {
      let rewards = [];
      res.rewards.forEach((category) =>
        category.items.forEach((reward) => {
          reward.category_id = category.id;
          rewards.push(reward);
        })
      );
      commit("set_categories", res.rewards);
      commit("set_rewards", rewards);
    } else return null;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const fetch_reward = async ({ commit }, reward_id) => {
  try {
    let [res] = await API.get(`/rewards/${reward_id}`);
    if (res._status === 200) {
      commit("set_reward", res.reward_item);
    } else return null;
  } catch (err) {
    console.error(err);
    return err;
  }
};
