export const get_journeys = (state) => state.journeys;

export const get_journey = (state) => state.journey;

export const get_bundle = (state) => (id) =>
  state.journey.bundles.filter((bundle) => bundle.id == id)[0];
export const get_attempts = (state) => state.journey.attempts;

export const get_attempts_left = (state) => state.journey.attempts_left;

/* export const get_journey_user = (state) => state.journeyUser; */
