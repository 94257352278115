import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
//import i18n from '@/i18n.js'

// APP
const TutorialView = () => import("@/views/app/TutorialView.vue");
const NotificationsView = () => import("@/views/app/NotificationsView.vue");
const DevPage = () => import("@/views/app/DevPage.vue");
const ExternalFrame = () => import("@/views/app/ExternalFrame.vue");

// START
const StartHub = () => import("@/views/start/StartHub.vue");
const StartOverview = () =>
  import("@/views/start/children-start/StartOverview");
const QuestOverview = () =>
  import("@/views/start/children-start/QuestOverview");
const AchievementOverview = () =>
  import("@/views/start/children-start/AchievementOverview.vue");
const AchievementDetail = () =>
  import("@/views/start/children-start/AchievementDetail.vue");

// AUTH
const LoginView = () => import("@/views/auth/LoginView.vue");
const LoginSSO = () => import("@/views/auth/LoginSSO.vue");
const RegisterView = () => import("@/views/auth/RegisterView.vue");

// COMMUNITY
const CommunityHub = () => import("@/views/community/CommunityHub.vue");
const CommunityInfo = () =>
  import("@/views/community/children-community/CommunityInfo");
const RankingView = () =>
  import("@/views/community/children-community/RankingView");
const RewardsView = () =>
  import("@/views/community/children-community/RewardsView");

// PROFILE - USER
const ProfileHub = () => import("@/views/profile/ProfileHub.vue");
const ProfileOverview = () =>
  import("@/views/profile/children-profile/ProfileOverview.vue");
const FriendsView = () =>
  import("@/views/profile/children-profile/FriendsView.vue");
const StatisticsView = () =>
  import("@/views/profile/children-profile/StatisticsView.vue");
const SettingsView = () =>
  import("@/views/profile/children-profile/SettingsView.vue");
const SubscriptionsView = () =>
  import("@/views/profile/children-profile/SubscriptionsView.vue");
const MailSubscription = () => import("@/views/profile/MailSubscription.vue");

// CONTENT
const ContentHub = () => import("@/views/content/ContentHub.vue");
const ContentOverview = () =>
  import("@/views/content/children-content/ContentOverview.vue");
const ReportsOverview = () =>
  import("@/views/content/children-content/ReportsOverview.vue");
const ReportDetail = () =>
  import("@/views/content/children-content/ReportDetail.vue");
const QuestionsOverview = () =>
  import("@/views/content/children-content/QuestionsOverview.vue");
const QuestionDetail = () =>
  import("@/views/content/children-content/QuestionDetail.vue");

// ERRORS
const FileNotFound = () => import("@/views/errors/FileNotFound.vue");
const AuthorizationError = () =>
  import("@/views/errors/AuthorizationError.vue");
const GeneralError = () => import("@/views/errors/GeneralError.vue");
const NotSupported = () => import("@/views/errors/NotSupported.vue");
const InitScreenError = () => import("@/views/errors/InitScreenError.vue");

// PLAY
const PlayHub = () => import("@/views/play/PlayHub.vue");
const PlayOverview = () =>
  import("@/views/play/children-play/PlayOverview.vue");
const JourneyOverview = () =>
  import("@/views/play/children-play/JourneyOverview.vue");
const WorksheetOverview = () =>
  import("@/views/play/children-play/WorksheetOverview.vue");

// QUIZ
const QuizSelectCategory = () => import("@/views/quiz/QuizSelectCategory.vue");
const QuizPlay = () => import("@/views/quiz/QuizPlay.vue");
const QuizSelectOpponent = () => import("@/views/quiz/QuizSelectOpponent.vue");

// JOURNEYS
const JourneyDetail = () => import("@/views/journeys/JourneyDetail.vue");
const JourneyBundle = () => import("@/views/journeys/JourneyBundle.vue");

// WORKSHEETS
const WorksheetDetail = () => import("@/views/worksheets/WorksheetDetail.vue");
const WorksheetPlay = () => import("@/views/worksheets/WorksheetPlay.vue");

// RESULTS
const ResultsHub = () => import("@/views/results/ResultsHub.vue");
const ResultsOverview = () =>
  import("@/views/results/children-results/ResultsOverview.vue");
const ResultsOverviewQuiz = () =>
  import("@/views/results/children-results/ResultsOverviewQuiz.vue");
const ResultsQuiz = () =>
  import("@/views/results/children-results/ResultsQuiz.vue");

// EXPLORE
const ExploreHub = () => import("@/views/explore/ExploreHub.vue");
const ExploreOverview = () =>
  import("@/views/explore/children-explore/ExploreOverview.vue");
const NuggetDetail = () =>
  import("@/views/explore/children-explore/NuggetDetail.vue");
const ExploreFavorites = () =>
  import("@/views/explore/children-explore/ExploreFavorites.vue");

import * as API from "@/plugins/servers/Api.js";

Vue.use(VueRouter);

const checkBrowser = (to, from, next) => {
  if (GetIEVersion() > 0) {
    console.log("This is IE " + GetIEVersion());
    router.replace("/not-supported");
  } else {
    return next();
    // return next(`/login?redirect=${to.path}`);
  }
};

const GetIEVersion = () => {
  //Checks a supported version of IE is being used. If not, an error page is shown.

  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  else if (navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
};

const checkToken = (to, from, next) => {
  if (GetIEVersion() > 0) {
    router.replace("/not-supported");
  } else {
    if (store.getters["auth/get_current_user"]) {
      if (store.getters["auth/get_current_user"].is_deleted === 1) {
        store.dispatch("auth/logout", {
          userId: store.getters["auth/get_current_user"].id,
          api_token: store.getters["auth/get_current_user"].apiToken,
        });

        next("/login");
      }
      API.setAuthHeader();
      return next();
    } else {
      next(`/login?next=${to.fullPath}`);
    }
  }
};

const checkAuth = async (to, from, next) => {
  if (GetIEVersion() > 0) {
    router.replace("/not-supported");
  } else {
    if (localStorage.token && localStorage.userId) {
      try {
        let res = await store.dispatch("auth/get_user", {
          token: localStorage.token,
          id: localStorage.userId,
        });
        if (res && !!res.user.id) {
          return next(to.query.next ? to.query.next : "/start");
        } else {
          return next();
        }
      } catch (err) {
        return next();
      }
    } else {
      return next();
    }
  }
};

const routes = [
  { path: "*", redirect: "/404" },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  {
    path: "/login/sso",
    name: "LoginSSO",
    alias: "/sso",
    component: LoginSSO,
    beforeEnter(to, from, next) {
      checkAuth(to, from, next);
    },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    beforeEnter(to, from, next) {
      checkBrowser(to, from, next);
    },
  },
  {
    path: "/",
    redirect: "/start",
  },
  {
    path: "/start",
    redirect: StartOverview,
    component: StartHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: StartOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "/quests",
        name: "Quests",
        component: QuestOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "/achievements",
        name: "Achievements",
        component: AchievementOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "/achievements/:id",
        name: "AchievementDetail",
        component: AchievementDetail,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
    ],
  },
  {
    path: "/dev",
    name: "Dev",
    component: DevPage,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/ext",
    name: "ExternalFrame",
    component: ExternalFrame,
    params: {
      url: "",
    },
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/community",
    component: CommunityHub,
    redirect: RankingView,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        path: "info",
        name: "CommunityInfo",
        component: CommunityInfo,
      },
      {
        alias: "",
        path: "ranking",
        name: "Ranking",
        component: RankingView,
      },
      {
        path: "rewards",
        name: "Rewards",
        component: RewardsView,
      },
    ],
  },
  {
    path: "/explore",
    redirect: ExploreOverview,
    component: ExploreHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        alias: "",
        path: "overview",
        name: "ExploreOverview",
        component: ExploreOverview,
      },
      {
        path: "favorites",
        name: "ExploreFavorites",
        component: ExploreFavorites,
      },
      {
        path: "nugget/:id?/:journey_id?/:bundle_id?/:step_id?/:user_step_id?",
        name: "NuggetDetail",
        component: NuggetDetail,
      },
    ],
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: TutorialView,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/journey/:id",
    name: "Journey",
    component: JourneyDetail,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/journey/:journey_id/bundle/:bundle_id/:step_id?",
    name: "JourneyBundle",
    component: JourneyBundle,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/worksheet/:id",
    name: "WorksheetDetail",
    component: WorksheetDetail,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/play/worksheet/:id/:round_id/:question_id",
    name: "WorksheetPlay",
    component: WorksheetPlay,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/profile",
    redirect: ProfileOverview,
    component: ProfileHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        alias: "",
        path: "overview",
        name: "ProfileOverview",
        component: ProfileOverview,
      },
      {
        path: "friends",
        name: "Friends",
        component: FriendsView,
      },
      {
        path: "statistics",
        name: "Statistics",
        component: StatisticsView,
      },
      {
        path: "settings",
        name: "Settings",
        component: SettingsView,
      },
      {
        path: "subscriptions",
        name: "Subscriptions",
        component: SubscriptionsView,
      },
    ],
  },
  {
    path: "/emails/unsubscribe/:token",
    name: "MailSubscription",
    component: MailSubscription,
    beforeEnter(to, from, next) {
      checkBrowser(to, from, next);
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsView,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/play",
    redirect: PlayOverview,
    component: PlayHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        path: "start",
        alias: "",
        component: PlayOverview,
        name: "PlayOverview",
      },
      {
        path: "journeys",
        component: JourneyOverview,
        name: "JourneyOverview",
      },
      {
        path: "worksheets",
        component: WorksheetOverview,
        name: "WorksheetOverview",
      },
    ],
  },
  {
    path: "/play/category/:type?/:quiz_id?/:opponent_id?",
    name: "QuizSelectCategory",
    component: QuizSelectCategory,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/play/opponent",
    name: "QuizSelectOpponent",
    component: QuizSelectOpponent,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/play/quiz/:type/:quiz_id?/:opponent_id?",
    name: "QuizPlay",
    component: QuizPlay,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
  },
  {
    path: "/results",
    redirect: ResultsOverview,
    component: ResultsHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        path: "overview/",
        alias: "/",
        name: "ResultsOverview",
        component: ResultsOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "overview/quiz/:type",
        name: "ResultsOverviewQuiz",
        component: ResultsOverviewQuiz,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "quiz/:quiz_id?",
        name: "ResultsQuiz",
        component: ResultsQuiz,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
    ],
  },
  {
    path: "/content",
    redirect: ContentOverview,
    component: ContentHub,
    beforeEnter(to, from, next) {
      checkToken(to, from, next);
    },
    children: [
      {
        path: "overview/",
        alias: "/",
        name: "ContentOverview",
        component: ContentOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "reports",
        name: "Reports",
        component: ReportsOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "reports/:id",
        name: "ReportDetail",
        component: ReportDetail,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "questions",
        name: "Questions",
        component: QuestionsOverview,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
      {
        path: "questions/:id",
        name: "QuestionDetail",
        alias: "/question/:id",
        component: QuestionDetail,
        beforeEnter(to, from, next) {
          checkToken(to, from, next);
        },
      },
    ],
  },
  { path: "/404", component: FileNotFound },
  { path: "/403", component: AuthorizationError },
  { path: "/500", component: GeneralError },
  { path: "/504", component: InitScreenError },
  { path: "/not-supported", component: NotSupported },
];

const router = new VueRouter({
  mode: "history",
  routes,
  base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  store.commit("app/set_previous_route", from);
  window.scrollTo(0, 0);
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  return next();
});

/* router.afterEach(() => { 
	window.scrollTo(0,0); }); */

export default router;
