<template>
  <v-dialog v-model="loading" persistent width="300">
    <v-card
      color="accent lighten-3"
      min-height="200"
      class="d-flex align-center justify-center"
    >
      <v-card-text>
        <div class="py-2 text--caption text-center">
          {{ $t("ng.waiting_text") }}
        </div>
        <v-progress-linear indeterminate color="primary" class="mb-0" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppLoading2", // TODO: rename?
  computed: {
    ...mapGetters("app", { loading: "get_loading" }),
  },
};
</script>
