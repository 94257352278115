<template>
  <div>
    <v-dialog
      v-model="menu.visible"
      fullscreen
      hide-overlay
      scrollable
      style="z-index: 995 !important"
      transition="dialog-top-transition"
    >
      <v-card color="primary">
        <v-card-title
          class="text--subtitle text-uppercase font-weight-bold justify-center white--text"
        >
          {{ $t("navBar.menu") }}
        </v-card-title>

        <v-card-text class="mb-16">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" offset-sm="3">
                <v-list color="transparent">
                  <v-list-item
                    class="white--text text-center"
                    active-class="menu-item-active"
                    :to="{ name: 'Dashboard' }"
                    dense
                    @click="set_menu_visibility(false)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="text--subtitle font-weight-light"
                      >
                        {{ $t("views.menu.dashboard") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list
                  v-for="(app, i) in visibleApps"
                  :key="i"
                  color="transparent"
                >
                  <v-subheader class="white--text justify-center overline">{{
                    app.name
                  }}</v-subheader>
                  <v-list-item
                    v-for="(link, idx) in app.links"
                    v-show="link.visible"
                    :key="idx"
                    class="white--text text-center"
                    active-class="menu-item-active"
                    :to="link.external ? null : link.route"
                    :href="link.external ? link.href : null"
                    :target="link.external ? '_blank' : '_self'"
                    @click="set_menu_visibility(false)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text--subtitle">
                        {{ link.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                v-if="languageCount > 1"
                cols="10"
                offset="1"
                sm="6"
                offset-sm="3"
              >
                <LanguageSelector
                  patch-user
                  background-color="primary darken-1"
                  text-color="white"
                  :custom-z-index="44444"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageSelector from "@/components/_app/LanguageSelector";

export default {
  name: "AppMenu",
  components: {
    LanguageSelector,
  },
  computed: {
    ...mapGetters("app", { menu: "get_menu" }),
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    apps() {
      var quiz = {
        name: this.$t("views.menu.apps.quiz"),
        links: [
          {
            title: this.$t("views.journeys.journey.plural"),
            route: { name: "JourneyOverview" },
            visible: process.env.VUE_APP_HIDE_JOURNEYS !== "true",
          },
          {
            title: this.$t("views.quiz.results"),
            route: { name: "ResultsOverview" },
            visible: true,
          },
          {
            title: this.exploreCustomName
              ? this.$t("ng.wiki")
              : this.$t("ng.explore"),
            route: { name: "ExploreOverview" },
            visible: true,
          },
          {
            title: this.$t("views.menu.currentQuests"),
            route: { name: "Quests" },
            visible: true,
          },
          {
            title: this.$t("views.menu.achievements"),
            route: { name: "Achievements" },
            visible: true,
          },
        ],
      };
      if (process.env.VUE_APP_HIDE_USER_QUESTIONS !== "true")
        quiz.links.push({
          title: this.$t("ng.your_content"),
          route: { name: "ContentOverview" },
          visible: true,
        });
      if (process.env.VUE_APP_HIDE_TUTORIAL !== "true")
        quiz.links.push({
          title: this.$t("views.menu.openTutorials"),
          route: { name: "Tutorial" },
          visible: true,
        });

      var admin = null;
      if (this.currentUser && [1, 2, 4, 5].includes(this.currentUser.role_id)) {
        admin = {
          name: this.$t("ng.administration"),
          links: [
            {
              title: this.$t("ng.administration_link"),
              href: `https://admin.${process.env.VUE_APP_INSTANCE_NAME}.p3l.app`,
              external: true,
              visible: true,
            },
          ],
        };
      }
      return [quiz, admin];
    },
    exploreCustomName() {
      return process.env.VUE_APP_EXPLORE_CUSTOM_NAME === "true";
    },
    visibleApps() {
      return this.apps.filter((a) => a);
    },
    languageCount() {
      return this.$i18n.availableLocales.length;
    },
  },
  watch: {
    currentUser(val) {
      if (!val) {
        this.$notify({
          type: "success",
          title: this.$t("views.subscriptions.success"),
          text: this.$t("toasters.loggedOut"),
        });
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapMutations("app", ["set_menu_visibility"]),
    async logoutUser() {
      this.set_menu_visibility(false);
      let payload = {
        userId: this.currentUser.id,
        api_token: this.currentUser.apiToken,
      };
      await this.logout(payload);
    },
  },
};
</script>

<style lang="scss">
.menu-item-active::before {
  opacity: 0 !important;
}
</style>
