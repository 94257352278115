import API from "@/plugins/servers/Api.js";

export const fetch_mailSubscriptionsWithToken = async ({ commit }, token) => {
  try {
    let [res] = await API.get(`/subscriptions/public/${token}`);
    commit("set_subscriptions", [res.setting]);
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_mailSubscriptions = async ({ commit }) => {
  try {
    let [res] = await API.get("/subscriptions/");
    commit("set_subscriptions", res.settings);
    return res;
  } catch (err) {
    return err;
  }
};

export const update_mailSubscriptionWithToken = async (_, payload) => {
  try {
    let [res] = await API.put(
      `/subscriptions/public/${payload.token}`,
      null,
      payload
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const update_mailSubscription = async (_, payload) => {
  try {
    let [res] = await API.put(`/subscriptions/${payload.name}`, null, payload);
    return res;
  } catch (err) {
    return err;
  }
};
