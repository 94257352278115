<template>
  <div>
    <v-dialog
      v-if="showDialog"
      :value="showDialog"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      transition="dialog-top-transition"
      scrollable
      style="z-index: 1000 !important"
      @click:outside="closeDialog()"
    >
      <v-card color="white" :loading="loading">
        <MenuCloseButton @clicked="closeDialog()" />
        <AppLoading v-if="loading" />

        <v-card-text v-if="!loading" class="pa-0">
          <section>
            <v-img src="/cdn/images/default.jpg" max-height="240" cover>
              <v-overlay v-if="isPrivate" absolute color="secondary darken-3">
                <span class="font-weight-bold text--subtitle">{{
                  $t("views.profile.private_profile")
                }}</span>
              </v-overlay>
            </v-img>
          </section>

          <section class="white">
            <v-container class="fill-height">
              <v-row align-center>
                <v-col
                  cols="12"
                  md="8"
                  class="d-flex align-center justify-start"
                  :class="{
                    'flex-column text-center': $vuetify.breakpoint.xsOnly,
                    'justify-center': $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <UserAvatar
                    :size="$vuetify.breakpoint.xsOnly ? 96 : 128"
                    :user-id="user.id"
                    :image="user.avatar_img"
                    disable-link
                    elevated
                    style="margin-top: -48px"
                  />
                  <div :class="{ 'ml-4': $vuetify.breakpoint.smAndUp }">
                    <h1
                      class="text--header-m primary--text font-weight-bold text-truncate"
                    >
                      {{ user.name }}
                    </h1>
                    <v-sheet
                      color="transparent"
                      :width="$vuetify.breakpoint.xsOnly ? 300 : 140"
                    >
                      <span class="overline primary--text"
                        >{{ $t("general.level") }} {{ user.level }}</span
                      >
                      <v-progress-linear
                        :value="user.level_percent"
                        color="primary"
                      />
                    </v-sheet>
                  </div>
                </v-col>
                <v-col
                  v-if="!isMyProfile"
                  cols="12"
                  md="4"
                  class="d-flex align-center justify-center"
                >
                  <div v-if="!user.is_bot" class="text-center">
                    <v-btn
                      v-if="
                        !user.is_friend &&
                        !pendingFriendRequest &&
                        user.allow_friends
                      "
                      small
                      depressed
                      color="primary"
                      @click="user.request_friendship()"
                    >
                      {{ $t("resources.friends.add_friend") }}
                    </v-btn>
                    <v-btn
                      v-if="!user.is_friend && pendingFriendRequest"
                      small
                      disabled
                      depressed
                      color="accent lighten-1"
                    >
                      {{ $t("resources.friends.invitation_pending") }}
                    </v-btn>
                    <v-btn
                      v-if="user.is_friend"
                      x-small
                      color="error"
                      outlined
                      @click="openRemoveFriendshipDialog()"
                    >
                      {{ $t("resources.friends.remove_friend") }}
                    </v-btn>
                  </div>
                  <div
                    v-if="
                      user.is_bot ||
                      (!user.allow_friends &&
                        !user.is_friend &&
                        !pendingFriendRequest)
                    "
                    class="text-center py-4"
                    :class="{ 'py-8': $vuetify.breakpoint.smAndDown }"
                  >
                    <span>
                      {{ $t("views.profile.no_friendships") }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </section>

          <section v-if="!isPrivate || isMyProfile" class="pb-4 pt-4">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  :class="{ 'my-2': $vuetify.breakpoint.xsOnly }"
                >
                  <v-row dense>
                    <v-col cols="6">
                      <StatsItemSimple
                        :value="user.stats.duels"
                        color="accent lighten-3"
                        :description="$t('views.profile.stats.played_duels')"
                      />
                    </v-col>
                    <v-col cols="6">
                      <StatsItemSimple
                        :value="user.stats.played_questions"
                        color="accent lighten-3"
                        :description="
                          $t('views.profile.stats.answered_questions')
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <StatsItemSimple
                        :value="user.stats.submitted_questions"
                        color="accent lighten-3"
                        :description="
                          $t('views.profile.stats.submitted_questions')
                        "
                      />
                    </v-col>
                    <v-col cols="6">
                      <StatsItemSimple
                        :value="user.stats.finished_journeys"
                        color="accent lighten-3"
                        :description="
                          $t('views.profile.stats.finished_journeys')
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="text-center">
                  <v-card color="transparent" elevation="0">
                    <v-card-text
                      :class="{
                        'd-flex align-center justify-space-between':
                          $vuetify.breakpoint.xsOnly,
                      }"
                    >
                      <v-progress-circular
                        size="96"
                        :value="user.stats.duel_win_percent"
                        color="primary"
                      >
                        <span>{{ user.stats.duel_win_percent }} %</span>
                      </v-progress-circular>
                      <h5 class="overline primary--text mt-2">
                        {{ $t("views.profile.stats.won_duels") }}
                      </h5>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="text-center">
                  <v-card color="white" elevation="0">
                    <v-card-text
                      :class="{
                        'd-flex align-center justify-space-between':
                          $vuetify.breakpoint.xsOnly,
                      }"
                    >
                      <v-progress-circular
                        size="96"
                        :value="user.stats.correct_questions_percent"
                        color="success"
                      >
                        <span>{{ user.stats.correct_questions_percent }}%</span>
                      </v-progress-circular>
                      <h5 class="overline success--text mt-2">
                        {{ $t("views.profile.stats.correct_answers") }}
                      </h5>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </section>

          <section v-if="!isPrivate || isMyProfile">
            <v-container>
              <h5
                class="primary--text overline font-weight bold text-center mb-2"
              >
                {{ $t("views.profile.subnavigation.achievements") }}
              </h5>
              <v-row dense class="justify-center py-1">
                <v-col
                  v-for="(achievement, i) in user.achievements"
                  :key="i"
                  cols="2"
                  sm="1"
                  class="text-center"
                >
                  <v-tooltip top color="primary" small>
                    <template v-slot:activator="{ on, attrs }">
                      <v-badge
                        :content="achievement.current_stage.stage"
                        overlap
                        bordered
                        offset-x="10"
                      >
                        <v-avatar
                          color="secondary darken-3"
                          size="32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="achievement.current_stage.image"
                            :alt="achievement.title"
                          />
                        </v-avatar>
                      </v-badge>
                    </template>
                    <span
                      >{{ achievement.subtitle }} -
                      {{ achievement.title }}</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </section>

          <section v-if="!isMyProfile" id="duels" class="primary pb-6 mt-2">
            <v-container>
              <h5 class="text--subtitle white--text mb-2">
                {{ $t("views.profile.friend_last_duels") }}
              </h5>
              <v-sheet
                v-if="user.last_duels.length == 0"
                width="70%"
                color="transparent"
                class="mt-2"
              >
                <span
                  class="white--text"
                  v-html="
                    $t('views.profile.invite_friend_question', {
                      name: user.name,
                    })
                  "
                />
                <div class="mt-4">
                  <v-tooltip color="white" right>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" class="d-inline-block" v-on="on">
                        <v-btn
                          small
                          color="success"
                          :disabled="
                            user.open_duel ||
                            user.open_duel_invitation ||
                            !user.can_duel
                              ? true
                              : false
                          "
                          @click="sendGameInvitation()"
                        >
                          {{ $t("resources.friends.invite_to_duel") }}
                        </v-btn>
                      </div>
                    </template>
                    <span
                      v-if="user.can_duel && !user.is_bot"
                      class="text--text"
                    >
                      {{
                        user.open_duel || user.open_duel_invitation
                          ? $t("views.profile.open_game")
                          : $t("resources.friends.invite_to_duel")
                      }}
                    </span>
                    <span
                      v-if="!user.can_duel && !user.is_bot"
                      class="text--text"
                    >
                      {{ $t("views.profile.no_duel_possible") }}
                    </span>
                    <span v-if="user.is_bot" class="text--text">
                      {{ $t("views.profile.no_invitation_possible") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-sheet>
              <div v-if="user.last_duels.length > 0">
                <v-row dense>
                  <v-col
                    v-for="(game, i) in user.last_duels"
                    :key="i"
                    cols="12"
                  >
                    <v-card
                      color="white"
                      :width="$vuetify.breakpoint.xs ? '100%' : '80%'"
                      tile
                      elevation="0"
                      :to="{
                        name: 'ResultsQuiz',
                        params: { quiz_id: game.id },
                      }"
                    >
                      <v-card-text
                        class="d-flex align-center pa-2"
                        :class="{
                          'flex-column text-center': $vuetify.breakpoint.xsOnly,
                        }"
                      >
                        <UserAvatar
                          :user-id="user.id"
                          :image="user.avatar_img"
                          size="48"
                          disable-link
                        />
                        <div class="d-flex flex-column ml-2">
                          <span class="overline pb-0">{{ user.name }}</span>
                          <span
                            class="text--caption mt-0"
                            :class="
                              myScore(game) > opponentScore(game)
                                ? 'success--text'
                                : 'error--text'
                            "
                            >{{
                              myScore(game) > opponentScore(game)
                                ? $t("views.yourGames.youWon")
                                : $t("views.yourGames.youLost")
                            }}</span
                          >
                        </div>
                        <v-spacer />
                        <div
                          class="d-flex justify-center align-center"
                          :class="{
                            'mb-2': $vuetify.breakpoint.xsOnly,
                            'mr-4': $vuetify.breakpoint.smAndUp,
                          }"
                        >
                          <span class="title"
                            >{{ myScore(game) }} :
                            {{ opponentScore(game) }}</span
                          >
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-tooltip
                      color="primary"
                      :right="$vuetify.breakpoint.smAndUp"
                      :top="$vuetify.breakpoint.xsOnly"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" class="d-inline-block" v-on="on">
                          <v-btn
                            small
                            color="success"
                            depressed
                            :disabled="
                              user.open_duel ||
                              user.open_duel_invitation ||
                              !user.can_duel
                                ? true
                                : false
                            "
                            @click="sendGameInvitation()"
                          >
                            {{ $t("resources.friends.invite_to_duel") }}
                          </v-btn>
                        </div>
                      </template>
                      <span v-if="user.can_duel && !user.is_bot">
                        {{
                          user.open_duel || user.open_duel_invitation
                            ? $t("views.profile.open_game")
                            : $t("resources.friends.invite_to_duel")
                        }}
                      </span>
                      <span v-if="!user.can_duel && !user.is_bot">
                        {{ $t("views.profile.no_duel_possible") }}
                      </span>
                      <span v-if="user.is_bot">
                        {{ $t("views.profile.no_invitation_possible") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>

    <RemoveFriendshipDialog
      v-if="showRemoveFriendshipDialog"
      :visible="showRemoveFriendshipDialog"
      :user="user"
      @close="hideRemoveFriendshipDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RemoveFriendshipDialog from "@/components/user/dialogs/RemoveFriendshipDialog";
import StatsItemSimple from "@/components/layout/StatsItemSimple";
import AppLoading from "@/components/_layout/AppLoading";
import MenuCloseButton from "@/components/_layout/MenuCloseButton";

export default {
  name: "ProfileDialog",
  components: {
    UserAvatar: () => import("@/components/user/UserAvatar"),
    RemoveFriendshipDialog,
    StatsItemSimple,
    AppLoading,
    MenuCloseButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      showRemoveFriendshipDialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    ...mapGetters("users", {
      user: "get_user",
      friendship_invitations: "get_friendship_invitations",
    }),
    showDialog() {
      return this.visible;
    },
    isMyProfile() {
      if (!this.currentUser) return false;
      return this.user.id === this.currentUser.id;
    },
    pendingFriendRequest() {
      if (!this.friendship_invitations) return false;
      let check = false;
      this.friendship_invitations.forEach((friendship) => {
        if (friendship.user.id == this.user.id) check = true;
      });
      return check;
    },
    isPrivate() {
      return this.user.is_bot || !this.user.allow_profile;
    },
  },
  watch: {
    visible: function (val) {
      val ? this.fetchData() : this.unloadData();
    },
  },
  methods: {
    ...mapMutations("users", ["set_user", "set_friendship_invitations"]),
    ...mapActions("quiz_invitations", ["create_invitation"]),
    ...mapActions("users", ["fetch_user", "fetch_friendship_invitations"]),
    ...mapActions("auth", ["get_user"]),
    async closeDialog() {
      await this.refetchUser();
      this.$emit("close");
    },
    async refetchUser() {
      await this.get_user(this.currentUser.id);
    },
    async fetchData() {
      this.loading = true;
      await this.fetch_user(this.userId);
      if (!this.user) {
        this.closeDialog();
        this.$notify({
          type: "error",
          title: this.$t("toasters.error"),
          text: this.$t("toasters.error"),
        });
      }
      await this.fetch_friendship_invitations();
      this.loading = false;
    },
    unloadData() {
      this.set_user(null);
    },
    getGameIconColor(game) {
      if (game.user_roundwins > game.opponent_roundwins) return "success";
      if (game.user_roundwins < game.opponent_roundwins) return "error";
      return "accent";
    },
    openRemoveFriendshipDialog() {
      this.showRemoveFriendshipDialog = true;
    },
    hideRemoveFriendshipDialog() {
      this.showRemoveFriendshipDialog = false;
    },
    async sendGameInvitation() {
      let payload = {
        user_id: this.user.id,
        reloadUsers: true,
      };
      try {
        let res = await this.create_invitation(payload);
        if (res._status === 200) {
          this.$notify({
            type: "success",
            title: this.$t("views.subscriptions.success"),
            text: this.$t("toasters.sendInvitationSuccess"),
          });
          this.fetchData();
        } else {
          this.$notify({
            type: "error",
            title: this.$t("views.subscriptions.error"),
            text: this.$t("toasters.sendInvitationError"),
          });
        }
      } catch (err) {
        this.$router.push("/500");
      }
    },
    myScore(game) {
      return game.results.filter((r) => r.user_id === this.currentUser.id)[0]
        .round_wins;
    },
    opponentScore(game) {
      return game.results.filter((r) => r.user_id === this.user.id)[0]
        .round_wins;
    },
  },
};
</script>

<style>
#duels {
  background-image: url("~/public/cdn/images/quest-reverse.png");
  background-position: top right;
  background-size: 128px 128px;
}
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
.v-dialog {
  overflow-y: visible !important;
}
</style>
