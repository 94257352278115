<template>
  <div>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      max-width="520"
      @click:outside="$emit('close')"
    >
      <v-card color="white" outlined>
        <SectionSpinner v-if="loading || !reward" />
        <v-card-text
          v-if="!loading && reward"
          class="d-flex flex-column align-center"
        >
          <v-sheet
            :color="getStatusColor()"
            class="py-1 px-3 my-2 d-flex align-center"
            :class="{ 'white--text': reward.user_reward.is_unlocked }"
          >
            <span class="text--caption mr-1">{{ getStatusText() }}</span>
            <PIcon
              :color="reward.user_reward.is_unlocked ? 'white' : 'secondary'"
              :icon="getStatusIcon()"
            />
          </v-sheet>

          <v-avatar
            color="accent lighten-3"
            :size="$vuetify.breakpoint.xsOnly ? 96 : 128"
            class="my-2"
          >
            <v-img :src="reward.img_url ? reward.img_url : ''" />
          </v-avatar>

          <h2 class="text--header-m text-center font-weight-bold">
            {{ reward.title }}
          </h2>

          <div
            v-if="reward.start_date && reward.expire_date"
            class="text--caption"
          >
            {{ startDate() }} - {{ endDate() }}
          </div>

          <div class="text--body px-4 mt-2 text-center">
            <span v-html="reward.subtitle" />
          </div>

          <div
            class="d-flex align-center justify-space-around mt-4"
            :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          >
            <v-sheet
              v-if="reward.expire_count && !reward.user_reward.is_unlocked"
              color="accent lighten-3"
              class="py-2 px-4 d-flex align-center"
              shaped
            >
              <PIcon small icon="sale" />
              <span class="text--caption ml-1">{{
                $t("views.community.reward.expire_count", {
                  count: reward.expire_count,
                })
              }}</span>
            </v-sheet>
            <v-btn
              v-if="reward.shop_link_url"
              text
              small
              color="primary"
              :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : 'ml-1'"
              :href="reward.shop_link_url"
              target="_blank"
            >
              {{ $t("views.community.goToShopText") }}
            </v-btn>
          </div>

          <!-- TODO change reward.id to reward.categorysomething -->
          <div
            v-if="reward.id === 1 && reward.user_reward.is_unlocked"
            class="mt-4"
          >
            <h2 class="overline text-center">
              {{ $t("views.community.yourVoucherCode") }}
            </h2>
            <v-text-field
              v-model="reward.user_reward.voucher"
              class="elevation-0"
              solo
              flat
              width="100%"
              hide-details
              background-color="accent lighten-3"
              readonly
            >
              <template #append>
                <v-btn icon @click="copyVoucher()">
                  <PIcon color="primary" icon="copy" />
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text small @click="$emit('close')">
            {{ $t("dialogs.actions.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <slot name="trigger" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionSpinner from "@/components/layout/SectionSpinner.vue";

export default {
  name: "RewardDialog",
  components: {
    SectionSpinner,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    rewardId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    ...mapGetters("rewards", { reward: "get_reward" }),
    dialog() {
      return this.showDialog;
    },
  },
  watch: {
    showDialog(visible) {
      if (visible) {
        this.fetchData();
      } else {
        this.set_reward(null);
      }
    },
  },
  methods: {
    ...mapActions("rewards", ["fetch_reward"]),
    ...mapMutations("rewards", ["set_reward"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_reward(this.rewardId);
      this.loading = false;
    },
    getStatusColor() {
      if (this.reward.isUnlocked) return "success";
      return "transparent";
    },
    getStatusText() {
      if (this.reward.isUnlocked)
        return this.$t("views.community.reward.unlocked");
      return this.$t("views.community.reward.atLevel", {
        level: this.reward.goal_score,
      });
    },
    getStatusIcon() {
      if (this.reward.isUnlocked) return "check";
      return "locked";
    },
    copyVoucher() {
      navigator.clipboard.writeText(this.reward.user_reward.voucher);
      this.$notify({
        type: "success",
        text: this.$t("resources.nuggets.toasters.copied"),
      });
    },
    startDate() {
      if (!this.reward.start_date) return "-";
      return new Date(this.reward.start_date).toLocaleDateString();
    },
    endDate() {
      if (!this.reward.expire_date) return "-";
      return new Date(this.reward.expire_date).toLocaleDateString();
    },
  },
};
</script>
