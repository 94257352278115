<template>
  <v-select
    v-model="selected"
    :items="languages"
    :solo="!outlined"
    :outlined="outlined"
    :background-color="backgroundColor"
    flat
    dense
    hide-details
    :disabled="disabled"
    :style="customZIndex ? `z-index: ${customZIndex}` : ''"
    @change="changeLanguage"
  >
    <template #item="{ item }">
      <div class="d-flex align-center">
        <country-flag
          ref="languageDropdown"
          :country="flagName(item)"
          size="normal"
          class="mb-0 mr-1"
        />
        <span>{{ $t("languages." + item + ".display") }}</span>
      </div>
    </template>
    <template #selection="{ item }">
      <div class="d-flex align-center justify-center">
        <country-flag
          ref="languageDropdown"
          :country="flagName(item)"
          size="normal"
          class="mb-0 mr-1"
        />
        <span :class="`text--body ${textColor}--text`">{{
          $t("languages." + item + ".display")
        }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  name: "LanguageSelector",
  components: {
    CountryFlag,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    patchUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "accent lighten-3",
    },
    textColor: {
      type: String,
      required: false,
      default: "text",
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    customZIndex: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selected: this.$i18n.locale,
      languages: this.$i18n.availableLocales,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
  },
  methods: {
    ...mapActions("users", ["update_user"]),
    ...mapMutations("app", ["set_language", "set_loading"]),
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukraininian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2).toLowerCase();
    },
    async changeLanguage() {
      this.set_loading(true);
      let worked = true;
      if (this.patchUser) {
        let payload = { user_id: this.currentUser.id, language: this.selected };
        let res = await this.update_user(payload);
        if (res._status !== 200) worked = false;
      }
      this.set_loading(false);
      if (worked) {
        this.set_language(this.selected);
        localStorage.setItem("locale", this.selected);
        this.$router.go();
      }
    },
  },
};
</script>
