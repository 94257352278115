export const state = {
  worksheets: [],
  worksheet: null,
  worksheet_rounds: null,
  current_category: null,
  navigation_visible: false,
  progress_triggered: false,
  current_question_id: null,
  current_round_id: null,
  /* current_progress: 0.2 */
};
