import API from "@/plugins/servers/Api.js";
import { Journey } from "@/models/journeys/Journey";
import { Nugget } from "@/models/nuggets/Nugget";
//import { JourneyBundle } from '@/models/JourneyBundle';

export const fetch_journeys = async ({ commit }) => {
  try {
    let [res] = await API.get("/journeys/");
    // TODO: res.success -> res._status
    if (res.success) {
      let journeys = res.journeys.map((journey) => {
        return new Journey(journey, journey.user_journey);
      });
      commit("set_journeys", journeys);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_journey = async ({ commit }, payload) => {
  try {
    commit("set_journey", null);
    let [res] = await API.get(`/journeys/${payload.id}`);
    if (res.success) {
      commit("set_journey", new Journey(res.journey, res.user_journey));
      return res;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const start_journey = async (_, payload) => {
  try {
    let [res] = await API.post("/journeys/start", null, payload);
    if (res.success) {
      return true;
    } else return false;
  } catch (err) {
    return err;
  }
};

export const reset_journey = async (_, payload) => {
  try {
    let [res] = await API.post("/journeys/reset", null, payload);
    if (res.success) {
      return true;
    } else return false;
  } catch (err) {
    return err;
  }
};

export const reset_bundle = async (_, payload) => {
  try {
    let [res] = await API.post("/journeys/bundles/reset", null, payload);
    if (res.success) {
      return true;
    } else return false;
  } catch (err) {
    return err;
  }
};

export const fetch_journey_nugget = async ({ commit }, nuggetId) => {
  let payload = { nugget_id: nuggetId };
  try {
    let [res] = await API.post("/journeys/get_nugget", null, payload);
    if (res.success) {
      commit("nuggets/set_nugget", new Nugget(res.nugget), { root: true });
    } else return null;
  } catch (err) {
    return err;
  }
};

export const finish_nugget = async (_, payload) => {
  try {
    let [res] = await API.post("/journeys/finish_nugget", null, payload);
    if (res.success) {
      return res;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const start_quiz = async (_, payload) => {
  try {
    let [res] = await API.post("/journeys/get_quiz", null, payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const download_certificate = async (_, payload) => {
  try {
    let res = await API.download(`/journeys/${payload.id}/certificate`);
    return res;
  } catch (err) {
    return null;
  }
};
