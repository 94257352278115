export const set_topnavigation_settings = (state, settings) => {
  //state.topNavigation.type = settings.type ? settings.type : 'default';
  state.topNavigation.title = settings.title ? settings.title : "";
  state.topNavigation.visible = settings.visible ? settings.visible : false;
  state.topNavigation.backgroundColor = settings.backgroundColor
    ? settings.backgroundColor
    : "primary";
  state.topNavigation.color = settings.color ? settings.color : "white";
  //state.topNavigation.showLogo = settings.showLogo ? settings.showLogo : false;
  state.topNavigation.showBackArrow = settings.showBackArrow
    ? settings.showBackArrow
    : false;
  state.topNavigation.showAvatar = settings.showAvatar
    ? settings.showAvatar
    : false;
  state.topNavigation.hideOnScroll = settings.hideOnScroll
    ? settings.hideOnScroll
    : false;
  state.topNavigation.elevateOnScroll = settings.elevateOnScroll
    ? settings.elevateOnScroll
    : false;
  state.topNavigation.invertedScroll = settings.invertedScroll
    ? settings.invertedScroll
    : false;
  state.topNavigation.scrollThreshold = settings.scrollThreshold
    ? settings.scrollThreshold
    : 64;
  state.topNavigation.fixed = settings.fixed ? settings.fixed : false;
  state.topNavigation.height = settings.height ? settings.height : 52;
  state.topNavigation.elevation = settings.elevation ? settings.elevation : 4;
  state.topNavigation.showListIcon = settings.showListIcon
    ? settings.showListIcon
    : false;
  state.topNavigation.listIconRoute = settings.listIconRoute
    ? settings.listIconRoute
    : null;
  state.topNavigation.listIconTooltipText = settings.listIconTooltipText
    ? settings.listIconTooltipText
    : "";
  state.topNavigation.showSettingsIcon = settings.showSettingsIcon
    ? settings.showSettingsIcon
    : false;
  state.topNavigation.settingsIconRoute = settings.settingsIconRoute
    ? settings.settingsIconRoute
    : null;
  state.topNavigation.showCloseIcon = settings.showCloseIcon
    ? settings.showCloseIcon
    : false;
  //state.topNavigation.logo = settings.logo ? settings.logo : false;
  state.topNavigation.tabs = settings.tabs ? settings.tabs : null;
  state.topNavigation.showNotificationsIcon =
    settings.showNotificationsIcon === false ? false : true;
};

export const set_topnavigation_visibility = (state, visible) =>
  (state.topNavigation.visible = visible);
export const set_bottomnavigation_visibility = (state, visible) =>
  (state.bottomNavigation.visible = visible);

export const set_bottomnavigation_settings = (state, settings) => {
  state.bottomNavigation.visible = settings.visible ? settings.visible : false;
};
export const set_title = (state, title) => (state.topNavigation.title = title);

export const set_previous_route = (state, route) => {
  state.topNavigation.previousRoute = route;
};

export const set_alert = (state, alert) => (state.alert = alert);

export const set_menu_visibility = (state, visible) =>
  (state.menu.visible = visible);
export const set_toggle_menu = (state) =>
  (state.menu.visible = !state.menu.visible);

export const set_language = (state, language) => (state.language = language);
export const set_loading = (state, loading) => (state.loading = loading);
