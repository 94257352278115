import { Notification } from "@/models/notifications/Notification";
// user:
// Number id
// String name
// String img_url

export class NotificationDuelInvitation extends Notification {
  constructor(obj) {
    super(obj);
    this.user = obj.user;
    this.link = { name: "QuizSelectOpponent" };
    this.image = obj.user.img_url;
    this.background_image = "not-duel-invitation.png";
    this.avatar_border = "primary";
  }
}
