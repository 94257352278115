import API from "@/plugins/servers/Api.js";
import store from "@/store/index.js";
import { User } from "@/models/users/User";
import { FriendshipInvitation } from "@/models/users/FriendshipInvitation";
import { Journey } from "@/models/journeys/Journey";
import { Nugget } from "@/models/nuggets/Nugget";

export const fetch_avatars = async ({ commit }) => {
  try {
    let [res] = await API.get("/avatars/");

    if (res) {
      commit("set_avatars", res.avatars);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_user = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.patch(`/users/${payload.user_id}`, null, payload);
    if (res) dispatch("auth/get_user", payload.user_id, { root: true });
    return res;
  } catch (err) {
    return err;
  }
};

export const update_user_settings = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.patch(
      `/users/${payload.user_id}/settings`,
      null,
      payload
    );
    if (res) dispatch("auth/get_user", payload.user_id, { root: true });
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_user = async ({ commit }, id) => {
  try {
    let [res] = await API.get(`/users/${id}`);

    if (res._status === 200) commit("set_user", new User(res.user));
  } catch (err) {
    return null;
  }
};

export const fetch_friends = async ({ commit }) => {
  try {
    let [res] = await API.get("/friendships/");

    if (res._status === 200) {
      let friends = res.friends.map((friend) => {
        let user = new User(friend.user);
        user.open_duel = friend.duel;
        user.open_duel_invitation = friend.duel_invitation;
        user.can_duel = friend.can_duel;
        return user;
      });
      commit("set_friends", friends);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_friendship_invitations = async ({ commit }) => {
  try {
    let [res] = await API.get("/friendships/invitations");

    if (res._status === 200) {
      let invitations = res.invitations.map((invitation) => {
        return new FriendshipInvitation(invitation);
      });
      commit("set_friendship_invitations", invitations);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const add_friendship_invitation = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post("/friendships/invitations", null, payload);
    if (res._status === 200) {
      await dispatch("fetch_friends");
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const delete_friendship_invitation = async (
  { commit, dispatch },
  payload
) => {
  try {
    let [res] = await API.delete("/friendships/invitations", null, payload);
    if (res._status === 200) {
      commit("remove_friendship_invitation", payload);
      await dispatch("fetch_friends");
      await dispatch(
        "auth/get_user",
        store.getters["auth/get_current_user"].id,
        { root: true }
      );
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const accept_friendship_invitation = async (
  { commit, dispatch },
  payload
) => {
  try {
    let [res] = await API.post("/friendships/", null, payload);
    if (res._status === 200) {
      commit("remove_friendship_invitation", payload);
      await dispatch("fetch_friends");
      await dispatch(
        "auth/get_user",
        store.getters["auth/get_current_user"].id,
        { root: true }
      );
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const delete_friendship = async ({ dispatch }, payload) => {
  //export const delete_friendship = async ({ commit }, payload) => {
  try {
    let [res] = await API.delete("/friendships/", null, payload);
    if (res._status === 200) await dispatch("fetch_friends");
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_dashboard_data = async ({ commit }) => {
  try {
    let [res] = await API.get("/users/dashboard");

    if (res._status === 200) {
      let journeys = res.journeys.map((journey) => {
        return new Journey(journey, journey.user_journey);
      });
      let nuggets = res.nuggets.map((nugget) => {
        return new Nugget(nugget);
      });

      let data = {
        journeys: journeys,
        quest: res.quest,
        stats: res.stats,
        nuggets: nuggets,
        ranking: res.ranking,
        friend_invitations: res.friend_invitations,
        quiz_invitations: res.quiz_invitations,
        quizzes: res.quizzes,
        content: res.content,
      };
      commit("set_dashboard_data", data);
    }
  } catch (err) {
    return null;
  }
};

export const delete_user = async (_, payload) => {
  try {
    let [res] = await API.delete(`/users/${payload.id}`);
    return res;
  } catch (err) {
    return err;
  }
};
