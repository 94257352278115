/* eslint-disable no-mixed-spaces-and-tabs */
export class AchievementStage {
  id = -1;
  stage = -1;
  image = "";
  goal_score = -1;
  price = -1;

  is_achieved = false;

  constructor(stage, current_score) {
    try {
      this.id = stage.id;
      this.stage = stage.stage;
      this.image = stage.image;
      this.goal_score = stage.goal_score;
      this.price = stage.price;
      this.is_achieved = current_score >= this.goal_score;
    } catch (err) {
      console.error(err);
    }
  }
}
