import API from "@/plugins/servers/Api.js";

export const fetch_ranking = async ({ commit }) => {
  try {
    let [res] = await API.get("/users/ranking");
    if (res.success) {
      var ranking = res.ranking.map((user, i) => {
        user.rank = i + 1;
        return user;
      });
      commit("set_ranking", ranking);
      commit("set_position", res.position);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_ranking_global = async ({ commit }) => {
  try {
    let [res] = await API.get("/users/ranking?global=1");
    if (res.success) {
      var ranking = res.ranking.map((user, i) => {
        user.rank = i + 1;
        return user;
      });
      commit("set_ranking", ranking);
      commit("set_position", res.position);
    } else return null;
  } catch (err) {
    return err;
  }
};
