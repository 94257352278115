import { AchievementStage } from "@/models/achievements/AchievementStage";

export class Achievement {
  id = -1;
  body = "";
  title = "";
  subtitle = "";
  image = "";
  current_score = -1;
  stages = [];

  constructor(obj) {
    try {
      this.id = obj.id;
      this.body = obj.body;
      this.title = obj.title;
      this.subtitle = obj.subtitle;
      this.image = obj.image;
      this.current_score = obj.user_achievement.score;
      this.stages = obj.stages.map((stage) => {
        return new AchievementStage(stage, this.current_score);
      });
    } catch (err) {
      console.error(err);
    }
  }

  get current_stage() {
    let stages = [...this.stages].sort((a, b) => a.stage - b.stage);
    let current_stage = stages.filter(
      (stage) => stage.goal_score > this.current_score
    )[0];
    return current_stage;
  }

  get count_to_next_goal() {
    return this.current_stage.goal_score - this.current_score;
  }
}
