import { Notification } from "@/models/notifications/Notification";
// quest:
// Number id
// String img_url
// Number prize
// String type (?)

export class NotificationQuestFinished extends Notification {
  constructor(obj) {
    super(obj);
    this.quest = obj.quest;
    this.link = { name: "Quests" };
    this.background_image = "not-quest-finished.png";
    this.avatar_border = "success";
  }
}
