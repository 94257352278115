import Vue from "vue";
import Vuex from "vuex";

import notifications from "./modules/notifications/module";
import ranking from "./modules/ranking/module";
import statistics from "./modules/statistics/module";
import tutorial from "./modules/tutorial/module";
import achievements from "./modules/achievements/module";
import quests from "./modules/quests/module";
import nuggets from "./modules/nuggets/module";
import journeys from "./modules/journeys/module";
import rewards from "./modules/rewards/module";
import subscriptions from "./modules/subscriptions/module";
import quiz_invitations from "./modules/quiz_invitations/module";
import users from "./modules/users/module";
import app from "./modules/app/module";
import media from "./modules/media/module";
import quizzes from "./modules/quizzes/module";
import questions from "./modules/questions/module";
import auth from "./modules/auth/module";
import community from "./modules/community/module";
import worksheets from "./modules/worksheets/module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications,
    ranking,
    statistics,
    tutorial,
    achievements,
    quests,
    nuggets,
    journeys,
    rewards,
    subscriptions,
    quiz_invitations,
    users,
    app,
    media,
    quizzes,
    questions,
    auth,
    community,
    worksheets,
  },
});
