import { Notification } from "@/models/notifications/Notification";
// user:
// Number id
// String name
// String img_url

export class NotificationFriendshipReceived extends Notification {
  constructor(obj) {
    super(obj);
    this.user = obj.user;
    this.link = { name: "Friends" };
    this.image = obj.user.img_url;
    this.avatar_border = "primary";
  }
}
