import API from "@/plugins/servers/Api.js";

export const fetch_quiz_invitations = async ({ commit }) => {
  try {
    let [res] = await API.get("/quiz_invitations/");
    commit("set_quiz_invitations", res.invitations);
    return res;
  } catch (err) {
    return err;
  }
};

export const create_invitation = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post("/quiz_invitations/", null, payload);
    if (res) {
      if (payload.reloadUsers) {
        await dispatch("fetch_quiz_invitations");
        await dispatch("users/fetch_friends", null, { root: true });
        await dispatch("quizzes/fetch_opponents", null, { root: true });
      }
      return res;
    }
    return false;
  } catch (err) {
    return err;
  }
};

export const delete_invitation = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.delete(`/quiz_invitations/${payload.user_id}`);
    if (payload.reloadUsers) {
      await dispatch("fetch_quiz_invitations");
      await dispatch("users/fetch_friends", null, { root: true });
      await dispatch("quizzes/fetch_opponents", null, { root: true });
    }
    return res;
  } catch (err) {
    return err;
  }
};
