import API from "@/plugins/servers/Api.js";
import { Notification } from "@/models/notifications/Notification";
import { NotificationAchievementUnlocked } from "@/models/notifications/types/NotificationAchievementUnlocked";
import { NotificationDuelFinished } from "@/models/notifications/types/NotificationDuelFinished";
import { NotificationDuelInvitation } from "@/models/notifications/types/NotificationDuelInvitation";
import { NotificationDuelTurn } from "@/models/notifications/types/NotificationDuelTurn";
import { NotificationFriendshipAccepted } from "@/models/notifications/types/NotificationFriendshipAccepted";
import { NotificationFriendshipReceived } from "@/models/notifications/types/NotificationFriendshipReceived";
import { NotificationFriendshipRejected } from "@/models/notifications/types/NotificationFriendshipRejected";
import { NotificationJourneyStarted } from "@/models/notifications/types/NotificationJourneyStarted";
import { NotificationLevelRaised } from "@/models/notifications/types/NotificationLevelRaised";
import { NotificationQuestFinished } from "@/models/notifications/types/NotificationQuestFinished";
import { NotificationQuestUnlocked } from "@/models/notifications/types/NotificationQuestUnlocked";
import { NotificationQuestionApproved } from "@/models/notifications/types/NotificationQuestionApproved";
import { NotificationQuestionRejected } from "@/models/notifications/types/NotificationQuestionRejected";
import { NotificationQuestionRevision } from "@/models/notifications/types/NotificationQuestionRevision";
import { NotificationRewardUnlocked } from "@/models/notifications/types/NotificationRewardUnlocked";

export const fetch_notifications = async ({ commit }) => {
  try {
    let [res] = await API.get("/notifications/");
    if (res._status === 200) {
      let notifications = res.notifications.map((notification) => {
        if (notification.type === "achievement_unlocked")
          return new NotificationAchievementUnlocked(notification);
        if (notification.type === "duel_finished")
          return new NotificationDuelFinished(notification);
        if (notification.type === "duel_invitation")
          return new NotificationDuelInvitation(notification);
        if (notification.type === "duel_turn")
          return new NotificationDuelTurn(notification);
        if (notification.type === "friend_accepted")
          return new NotificationFriendshipAccepted(notification);
        if (notification.type === "friend_invitation")
          return new NotificationFriendshipReceived(notification);
        if (notification.type === "friend_rejected")
          return new NotificationFriendshipRejected(notification);
        if (notification.type === "journey_started")
          return new NotificationJourneyStarted(notification);
        if (notification.type === "level_raised")
          return new NotificationLevelRaised(notification);
        if (notification.type === "quest_finished")
          return new NotificationQuestFinished(notification);
        if (notification.type === "quest_unlocked")
          return new NotificationQuestUnlocked(notification);
        if (notification.type === "question_accepted")
          return new NotificationQuestionApproved(notification);
        if (notification.type === "question_rejected")
          return new NotificationQuestionRejected(notification);
        if (notification.type === "question_edit")
          return new NotificationQuestionRevision(notification);
        if (notification.type === "reward_unlocked")
          return new NotificationRewardUnlocked(notification);
        return new Notification(notification);
      });
      commit("set_notifications", notifications);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_notification_count = async ({ commit }) => {
  try {
    let [res] = await API.get("/notifications/count");
    if (res._status === 200) {
      commit("set_notification_count", res.total);
      commit("set_notification_count_seen", res.seen);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const patch_notifications_seen = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.patch("/notifications/seen", null, payload);
    if (res) dispatch("fetch_notifications");
    if (res) dispatch("fetch_notification_count");
    return res;
  } catch (err) {
    return err;
  }
};

export const delete_notifications = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.patch("/notifications/delete", null, payload);
    if (res) dispatch("fetch_notifications");
    if (res) dispatch("fetch_notification_count");
    return res;
  } catch (err) {
    return err;
  }
};
