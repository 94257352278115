import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./app.scss";
import Notifications from "vue-notification";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faWindows,
  faBuromobelexperte,
} from "@fortawesome/free-brands-svg-icons";
// single import due to bundle size?

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PIcon from "@/components/_layout/PIcon";
import { ICONS } from "@/plugins/icons.js";

import CustomTitle from "@/components/_layout/CustomTitle";

Vue.config.productionTip = false;

library.add(fas, faWindows, faBuromobelexperte);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("PIcon", PIcon);
Vue.prototype.$icons = ICONS;

Vue.component("CustomTitle", CustomTitle);

Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
