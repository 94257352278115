import { Notification } from "@/models/notifications/Notification";
// level:
// Number id

export class NotificationLevelRaised extends Notification {
  constructor(obj) {
    super(obj);
    this.level = obj.level;
    this.link = { name: "ProfileOverview" };
    this.background_image = "not-level-raised.png";
  }
}
