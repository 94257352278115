import API from "@/plugins/servers/Api.js";

export const add_feedback = async (_, payload) => {
  try {
    let [res] = await API.post("/forms/feedback", null, payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const add_support = async (_, payload) => {
  try {
    let [res] = await API.post("/forms/support", null, payload);
    return res;
  } catch (err) {
    return err;
  }
};
