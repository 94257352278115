export const state = {
  topNavigation: {
    visible: false,
    title: "",
    backgroundColor: "",
    color: "",
    //showLogo: false,
    showBackArrow: false,
    showAvatar: false,
    elevation: 4,
    hideOnScroll: false,
    elevateOnScroll: false,
    scrollThreshold: 0,
    previousRoute: null,
    fixed: true,
    showListIcon: false,
    listIconRoute: null,
    listIconTooltipText: "",
    showSettingsIcon: false,
    settingsIconRoute: null,
    showCloseIcon: false,
    //type: 'default',
    //logo: false,
    tabs: null,
    showNotificationsIcon: true,
    // TODO: bundle icons in object
  },
  bottomNavigation: {
    visible: false,
  },
  alert: null,
  menu: {
    visible: false,
  },
  language: "",
  loading: false,
};
