import API from "@/plugins/servers/Api.js";
import { Nugget } from "@/models/nuggets/Nugget";

export const fetch_nuggets = async ({ commit }, category_ids) => {
  try {
    let categories = category_ids ? `?category_ids=${category_ids}` : "";
    let [res] = await API.get(`/nuggets/${categories}`);
    if (res._status === 200) {
      let nuggets = res.nuggets.map((nugget) => {
        return new Nugget(nugget);
      });
      commit("set_nuggets", nuggets);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_nugget = async ({ commit }, nuggetId) => {
  try {
    let [res] = await API.get(`/nuggets/${nuggetId}`);
    if (res._status === 200) {
      commit("set_nugget", new Nugget(res.nugget));
      let related = res.related.map((nugget) => {
        return new Nugget(nugget);
      });
      commit("set_related_nuggets", related);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const patch_nugget = async (_, payload) => {
  try {
    let [res] = await API.patch(`/nuggets/${payload.id}`, null, payload);
    if (res._status === 200) {
      return true;
    } else return false;
  } catch (err) {
    return err;
  }
};

export const fetch_nugget_token = async (_, payload) => {
  try {
    let [res] = await API.post("/media/tokens/nugget/video", null, payload);
    if (res._status === 200) {
      return res.token;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_quiz_nuggets = async ({ commit }, quiz_id) => {
  try {
    let [res] = await API.get(`/quizzes/${quiz_id}/nuggets`);
    if (res._status === 200) {
      let nuggets = res.nuggets.map((nugget) => {
        return new Nugget(nugget);
      });
      commit("set_quiz_nuggets", nuggets);
    } else return null;
  } catch (err) {
    return err;
  }
};
