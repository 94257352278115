<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" text small v-bind="attrs" v-on="on">
        <span :class="[{ 'caption mt-1': withIcon }]">{{
          $t("dialogs.feedback.feedback")
        }}</span>
        <PIcon v-if="withIcon" icon="star" :color="color" />
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-card-title class="justify-center primary--text overline">
        <span>{{ $t("dialogs.feedback.feedback") }}</span>
      </v-card-title>

      <v-card-text v-if="feedback_given">
        {{ $t("dialogs.feedback.feedback_success") }}
      </v-card-text>

      <v-card-text v-if="!feedback_given">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.feedback.props.usability") }}
              </h5>
              <v-rating
                v-model="rating_usability"
                color="primary"
                background-color="primary"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.feedback.props.design") }}
              </h5>
              <v-rating
                v-model="rating_design"
                color="primary"
                background-color="primary"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.feedback.props.content") }}
              </h5>
              <v-rating
                v-model="rating_content"
                color="primary"
                background-color="primary"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.feedback.props.message") }}
              </h5>
              <v-textarea
                v-model="message"
                no-resize
                outlined
                required
                :rules="[rules.maxLength]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!feedback_given"
          color=""
          text
          small
          @click="closeDialog()"
        >
          {{ $t("views.questions.cancel") }}
        </v-btn>
        <v-btn
          v-if="!feedback_given"
          color="success"
          text
          small
          :disabled="loading || !validMsg"
          @click="submit()"
        >
          {{ $t("views.questions.create") }}
        </v-btn>
        <v-btn
          v-if="feedback_given"
          color="primary"
          text
          small
          @click="closeDialog()"
        >
          {{ $t("dialogs.actions.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FeedbackDialog",
  props: {
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "check",
    },
    smallIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      rating_usability: 0,
      rating_design: 0,
      rating_content: 0,
      message: "",
      feedback_given: false,
      loading: false,
      rules: {
        maxLength: (v) =>
          v.length <= 255 ||
          this.$t("ng.too_many_chars_hint", {
            count: v.length,
            max: 255,
          }),
      },
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    validMsg() {
      return (
        //this.message.length > 0 &&
        this.message.length <= 255 &&
        this.rating_content !== 0 &&
        this.rating_design !== 0 &&
        this.rating_usability !== 0
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) this.initDialog();
    },
  },
  methods: {
    ...mapActions("app", ["add_feedback"]),
    initDialog() {
      this.feedback_given = false;
      this.rating_usability = 0;
      this.rating_design = 0;
      this.rating_content = 0;
      this.message = "";
    },
    closeDialog() {
      this.dialog = false;
      this.feedback_given = false;
    },
    async submit() {
      this.loading = true;
      let payload = {
        usability: this.rating_usability,
        design: this.rating_design,
        content: this.rating_content,
        message: this.message,
      };

      let res = await this.add_feedback(payload);
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("views.subscriptions.success"),
          text: this.$t("dialogs.feedback.notification_success"),
        });
        this.feedback_given = true;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("views.subscriptions.error"),
          text: this.$t("dialogs.feedback.notification_error"),
        });
      }
    },
  },
};
</script>

<style></style>
