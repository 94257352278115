import API from "@/plugins/servers/Api.js";

export const check_external_token = async ({ dispatch, commit }, payload) => {
  try {
    let [res] = await API.post("/auth/sso", null, payload);
    if (!res.logged_in) {
      commit("set_register_userinfo", res.sso_user);
      commit("set_register_code", res.register_code);
    } else {
      localStorage.setItem("token", res.apiToken);
      await dispatch("get_user");
    }
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};
export const check_sso_code = async ({ dispatch, commit }, payload) => {
  try {
    let [res] = await API.post("/oauth2/sso/code", null, payload);
    if (!res.logged_in) {
      if (res.assign_structures && res.assign_structures.length > 0)
        commit("set_register_assign_structures", res.assign_structures);
      commit("set_register_userinfo", res.sso_user_registration);
      commit("set_register_code", res.sso_user_registration.register_token);
    } else {
      localStorage.setItem("token", res.apiToken);
      await dispatch("get_user");
    }
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const validate_code = async ({ commit }, payload) => {
  try {
    let [res] = await API.post("/auth/validate_code", null, payload);
    if (res._status === 200) {
      if (res.custom_fields)
        commit("set_register_custom_fields", res.custom_fields);
      if (res.assign_structures && res.assign_structures.length > 0)
        commit("set_register_assign_structures", res.assign_structures);
      if (res.select_structures && res.select_structures.length > 0)
        commit("set_register_select_structures", res.select_structures);
    }
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const validate_invitation = async ({ commit }, payload) => {
  try {
    let [res] = await API.post("/invitations/validate", null, payload);
    if (res._status === 200) {
      commit("set_register_invitation_token", payload.hash_token);
      if (res.custom_fields)
        commit("set_register_custom_fields", res.custom_fields);
      if (res.assign_structures && res.assign_structures.length > 0)
        commit("set_register_assign_structures", res.assign_structures);
      if (res.select_structures && res.select_structures.length > 0)
        commit("set_register_select_structures", res.select_structures);
      if (res.email) commit("set_register_userinfo", { email: res.email });
    }
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const validate_email = async (_, payload) => {
  try {
    let [res] = await API.post("/auth/validate_email", null, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const validate_username = async (_, payload) => {
  try {
    let [res] = await API.post("/auth/validate_username", null, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const register_user = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post("/auth/register", null, payload);

    if (res && res.id) {
      // with code
      if (payload.code) {
        localStorage.setItem("token", res.apiToken);
        await dispatch("auth/get_user", res, { root: true });
      }
      // with invitation
      if (payload.hash_token) {
        localStorage.setItem("token", res.api_token);
        await dispatch("auth/get_user", res.user, { root: true });
      }
      return 200;
    }
  } catch (err) {
    console.error(err);
    return 500;
  }
};

export const reset_password = async (_, payload) => {
  try {
    let [res] = await API.post("/auth/password_reset", null, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const get_token = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post("/auth/login", null, { ...payload });
    if (res._status === 200) {
      localStorage.setItem("token", res.apiToken);
      await dispatch("get_user", res);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const logout = async ({ commit }, payload) => {
  try {
    let [res] = await API.post("/auth/logout", null, payload);

    if (res) {
      commit("set_current_user", null);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const get_user = async ({ commit }) => {
  try {
    let [res] = await API.get("/auth/user");
    if (res._status === 200) {
      localStorage.setItem("userId", res.user.id);
      commit("set_current_user", res.user);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
