import API from "@/plugins/servers/Api.js";

export const fetch_statistics = async ({ commit }, payload) => {
  try {
    let [res] = await API.get(
      `statistics/?time_frame=${payload.timeFrame}&time_pointer=${payload.timePointer}`
    );
    if (res._status === 200) commit("set_statistics", res);
    return res;
  } catch (err) {
    return err;
  }
};
