export class QuizFeedback {
  constructor(obj) {
    this.answer = obj.answer;
    this.question = obj.answer.question;

    this.correct = obj.answer.correct;
    this.blix_correct = obj.answer.blix_correct;
    this.blix_time = obj.answer.blix_time;

    this.was_finished = obj.was_finished ? obj.was_finished : false;
  }

  get correctAnswerIds() {
    let ids = [];
    if (!this.question) return [];
    this.question.answers.forEach((answer) => {
      if (answer.is_correct) ids.push(answer.id);
    });
    return ids;
  }

  get gapAnswerResults() {
    let answers = [];
    let user_answer = JSON.parse(this.answer.answer);
    this.question.answers.forEach((answer, index) => {
      answers.push({
        order: answer.order,
        answer_correct: answer.body,
        answer_user: user_answer[index],
        correct: answer.body.toLowerCase() === user_answer[index].toLowerCase(),
      });
    });
    return answers;
  }
}
