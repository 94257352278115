export const set_categories = (state, categories) => {
  state.categories = categories;
};

export const set_rewards = (state, rewards) => {
  state.rewards = rewards;
};

export const set_reward = (state, reward) => {
  state.reward = reward;
};
