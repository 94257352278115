export const state = {
  quiz: null,
  quiz_feedback: null,

  quiz_result: null,
  quiz_results: null,
  quiz_result_answer: null,
  quiz_open_games: null,

  quiz_categories: null,

  quiz_opponent: null,
  quiz_info: null,

  quiz_opponents: [],
};
