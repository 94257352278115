import store from "@/store/index.js";

export class QuizResult {
  constructor(obj) {
    this.id = obj.id;
    //this.quiz_id = obj.quiz_id;
    this.options = obj.options;
    this.results = obj.results;
    this.is_finished = obj.is_finished;
    this.turn_id = obj.turn_id;
    this.user_id = store.getters["auth/get_current_user"].id;

    this.round_count = obj.round_count;
    this.quiz_rounds = obj.quiz_rounds;

    this.question_count = obj.question_count;
    this.question_source = obj.question_source;

    this.users = {
      user: null,
      opponent: null, // opponent[n]?
    };
    this.generateUsers();
    this.score = this.generateScore();
  }

  generateUsers() {
    this.results.forEach((result) => {
      result.name = result.user_name;
      if (result.user_id === this.user_id) {
        this.users.user = {
          id: result.user.id,
          name: result.user.user_name,
          type: result.user.user_type,
          avatar: result.user.info.avatar,
          round_wins: result.round_wins,
        };
      } else {
        this.users.opponent = {
          id: result.user.id,
          name: result.user.user_name,
          type: result.user.user_type,
          avatar: result.user.info.avatar,
          round_wins: result.round_wins,
        };
      }
      // if !this.user?
    });
  }

  generateScore() {
    // TODO: score values directly from api?
    let score = {
      answers: 0,
      time: 0,
      //opponent_bonus: 0,
      total: 0,
    };
    if (!this.quiz_rounds) return score;

    this.quiz_rounds.forEach((round) => {
      if (!round.answers[this.user_id]) return false;
      round.answers[this.user_id].forEach((answer) => {
        score.answers += answer.blix_correct;
        score.time += answer.blix_time;
        //score.opponent_bonus += 0;
      });
    });
    score.total = score.answers + score.time;
    // + score.opponent_bonus;
    return score;
  }

  get user() {
    return this.users.user;
  }
  get opponent() {
    return this.users.opponent;
  }
}
