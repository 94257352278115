import API from "@/plugins/servers/Api.js";
import { Quiz } from "@/models/quiz/play/Quiz";
import { QuizFeedback } from "@/models/quiz/play/QuizFeedback";
import { QuizDuelResult } from "@/models/quiz/results/QuizDuelResult";
import { QuizSoloResult } from "@/models/quiz/results/QuizSoloResult";
import { QuizJourneyResult } from "@/models/quiz/results/QuizJourneyResult";
import { Worksheet } from "@/models/worksheets/Worksheet";
import { Nugget } from "@/models/nuggets/Nugget";

export const fetch_quiz_result = async ({ commit }, payload) => {
  try {
    let [res] = await API.get(`/quizzes/${payload.quiz_id}`);
    if (res._status === 200) {
      if (res.quiz.quiz_type === "solo") {
        commit("set_quiz_result", new QuizSoloResult(res.quiz));
        return false;
      }
      if (res.quiz.quiz_type === "duel") {
        commit("set_quiz_result", new QuizDuelResult(res.quiz));
        return false;
      }
      if (res.quiz.quiz_type === "journey") {
        let journey_data = {
          journey_id: res.journey_id,
          bundle_id: res.journey_bundle_id,
          step_id: res.step_id,
          user_step_id: res.user_step_id,
          next_user_step: res.next_user_step,
        };
        commit(
          "set_quiz_result",
          new QuizJourneyResult(res.quiz, journey_data)
        );
        return false;
      }
      console.error("unknown quiz type");
    }
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const fetch_quiz_results = async ({ commit }) => {
  try {
    let [res] = await API.get("/quizzes/");
    if (res._status === 200) {
      var quizzes = [];
      res.quizzes.forEach((quiz) => {
        if (quiz.quiz_type === "solo") {
          quizzes.push(new QuizSoloResult(quiz));
          return false;
        }
        if (quiz.quiz_type === "duel") {
          quizzes.push(new QuizDuelResult(quiz));
          return false;
        }
        if (quiz.quiz_type === "worksheet") {
          return false;
        }
        if (quiz.quiz_type === "journey") return false;
        console.error("unknown quiz type", quiz.quiz_type);
      });
      commit("set_quiz_results", quizzes);
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_quiz_results_open = async ({ commit }) => {
  try {
    let [res] = await API.get("/quizzes/?is_finished=0");
    if (res._status === 200) {
      var quizzes = [];
      res.quizzes.forEach((quiz) => {
        if (quiz.quiz_type === "solo") {
          quizzes.push(new QuizSoloResult(quiz));
          return false;
        }
        if (quiz.quiz_type === "duel") {
          quizzes.push(new QuizDuelResult(quiz));
          return false;
        }
        if (quiz.quiz_type === "journey") {
          quizzes.push(new QuizJourneyResult(quiz));
          return false;
        }
        if (quiz.quiz_type === "worksheet") {
          quizzes.push(new Worksheet(quiz));
          return false;
        }
        console.error("unknown quiz type", quiz.quiz_type);
      });
      commit("set_quiz_open_games", quizzes);
    }
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const create_quiz = async (_, payload) => {
  try {
    let [res] = await API.post("/quizzes/", null, payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const initialize_quiz_round = async (_, payload) => {
  try {
    let [res] = await API.post(
      `/quizzes/${payload.quiz_id}/initialize`,
      null,
      payload
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const play_quiz = async ({ commit }, payload) => {
  try {
    let [res] = await API.post(`/quizzes/${payload.quiz_id}/play`, null, null);
    if (res._status === 200) {
      let quiz = res.quiz;
      quiz.answers = res.answers; // TODO: cant they be grouped?
      commit("set_quiz", new Quiz(quiz));
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const answer_question = async ({ commit }, payload) => {
  try {
    //if(!payload.answers) return false; // tmp
    let [res] = await API.post(
      `/quizzes/${payload.quiz_id}/answer`,
      null,
      payload
    );
    if (res._status === 200) {
      commit("set_quiz_feedback", new QuizFeedback(res));
      let nuggets = res.nuggets.map((nugget) => {
        return new Nugget(nugget);
      });
      commit("nuggets/set_quiz_nuggets", nuggets, { root: true });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_categories = async ({ commit }, payload) => {
  try {
    let limitString = payload.limit ? `&limit=${payload.limit}` : "";
    let quizString = payload.quiz_id ? `&quiz_id=${payload.quiz_id}` : "";
    let opponentString = payload.opponent_id
      ? `&opponent_id=${payload.opponent_id}`
      : "";
    let metaString = payload.meta ? payload.meta : "";
    let [res] = await API.get(
      `/quizzes/categories?${limitString}${quizString}${metaString}${opponentString}`
    );
    commit("set_quiz_categories", res.categories);
    commit("set_quiz_opponent", res.opponent);
    commit("set_quiz_info", res.quiz);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetch_answer = async ({ commit }, payload) => {
  try {
    let [res] = await API.get(
      `/quizzes/${payload.quiz_id}/answers/${payload.answer_id}`
    );
    commit("set_quiz_result_answer", res.answer);
    let nuggets = res.nuggets.map((nugget) => {
      return new Nugget(nugget);
    });
    commit("nuggets/set_quiz_nuggets", nuggets, { root: true });
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_opponents = async ({ commit }) => {
  try {
    let [res] = await API.get("/quizzes/opponents");
    if (res._status === 200) {
      commit("set_quiz_opponents", res.opponents);
      return res;
    }
  } catch (err) {
    return err;
  }
};
