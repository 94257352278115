var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-avatar',{class:{
      'elevation-2': _vm.elevated && !_vm.highlighted && !_vm.disableLink,
      clickable: !_vm.disableLink,
      highlighted: _vm.highlighted,
    },attrs:{"size":_vm.size,"tile":_vm.tile},on:{"click":function($event){$event.stopPropagation();return _vm.showProfile()}}},[_c('v-img',{class:{ transparent: _vm.transparent },attrs:{"src":_vm.image,"alt":_vm.disableLink
          ? _vm.$t('images.user.avatar_inactive')
          : _vm.$t('images.user.avatar_active')}})],1),(!_vm.disableLink)?_c('ProfileDialog',{attrs:{"visible":_vm.showProfileDialog,"user-id":_vm.userId},on:{"close":function($event){return _vm.closeProfile()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }