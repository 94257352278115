export const get_topnavigation_settings = (state) => state.topNavigation;

export const get_bottomnavigation_settings = (state) => state.bottomNavigation;

export const get_alert = (state) => state.alert;

export const get_menu = (state) => state.menu;

export const get_language = (state) => state.language;

export const get_loading = (state) => state.loading;
