var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showDialog)?_c('v-dialog',{staticStyle:{"z-index":"1000 !important"},attrs:{"value":_vm.showDialog,"max-width":"800","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"transition":"dialog-top-transition","scrollable":""},on:{"click:outside":function($event){return _vm.closeDialog()}}},[_c('v-card',{attrs:{"color":"white","loading":_vm.loading}},[_c('MenuCloseButton',{on:{"clicked":function($event){return _vm.closeDialog()}}}),(_vm.loading)?_c('AppLoading'):_vm._e(),(!_vm.loading)?_c('v-card-text',{staticClass:"pa-0"},[_c('section',[_c('v-img',{attrs:{"src":"/cdn/images/default.jpg","max-height":"240","cover":""}},[(_vm.isPrivate)?_c('v-overlay',{attrs:{"absolute":"","color":"secondary darken-3"}},[_c('span',{staticClass:"font-weight-bold text--subtitle"},[_vm._v(_vm._s(_vm.$t("views.profile.private_profile")))])]):_vm._e()],1)],1),_c('section',{staticClass:"white"},[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align-center":""}},[_c('v-col',{staticClass:"d-flex align-center justify-start",class:{
                  'flex-column text-center': _vm.$vuetify.breakpoint.xsOnly,
                  'justify-center': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"cols":"12","md":"8"}},[_c('UserAvatar',{staticStyle:{"margin-top":"-48px"},attrs:{"size":_vm.$vuetify.breakpoint.xsOnly ? 96 : 128,"user-id":_vm.user.id,"image":_vm.user.avatar_img,"disable-link":"","elevated":""}}),_c('div',{class:{ 'ml-4': _vm.$vuetify.breakpoint.smAndUp }},[_c('h1',{staticClass:"text--header-m primary--text font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('v-sheet',{attrs:{"color":"transparent","width":_vm.$vuetify.breakpoint.xsOnly ? 300 : 140}},[_c('span',{staticClass:"overline primary--text"},[_vm._v(_vm._s(_vm.$t("general.level"))+" "+_vm._s(_vm.user.level))]),_c('v-progress-linear',{attrs:{"value":_vm.user.level_percent,"color":"primary"}})],1)],1)],1),(!_vm.isMyProfile)?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"4"}},[(!_vm.user.is_bot)?_c('div',{staticClass:"text-center"},[(
                      !_vm.user.is_friend &&
                      !_vm.pendingFriendRequest &&
                      _vm.user.allow_friends
                    )?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.user.request_friendship()}}},[_vm._v(" "+_vm._s(_vm.$t("resources.friends.add_friend"))+" ")]):_vm._e(),(!_vm.user.is_friend && _vm.pendingFriendRequest)?_c('v-btn',{attrs:{"small":"","disabled":"","depressed":"","color":"accent lighten-1"}},[_vm._v(" "+_vm._s(_vm.$t("resources.friends.invitation_pending"))+" ")]):_vm._e(),(_vm.user.is_friend)?_c('v-btn',{attrs:{"x-small":"","color":"error","outlined":""},on:{"click":function($event){return _vm.openRemoveFriendshipDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("resources.friends.remove_friend"))+" ")]):_vm._e()],1):_vm._e(),(
                    _vm.user.is_bot ||
                    (!_vm.user.allow_friends &&
                      !_vm.user.is_friend &&
                      !_vm.pendingFriendRequest)
                  )?_c('div',{staticClass:"text-center py-4",class:{ 'py-8': _vm.$vuetify.breakpoint.smAndDown }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("views.profile.no_friendships"))+" ")])]):_vm._e()]):_vm._e()],1)],1)],1),(!_vm.isPrivate || _vm.isMyProfile)?_c('section',{staticClass:"pb-4 pt-4"},[_c('v-container',[_c('v-row',[_c('v-col',{class:{ 'my-2': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"12","lg":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('StatsItemSimple',{attrs:{"value":_vm.user.stats.duels,"color":"accent lighten-3","description":_vm.$t('views.profile.stats.played_duels')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('StatsItemSimple',{attrs:{"value":_vm.user.stats.played_questions,"color":"accent lighten-3","description":_vm.$t('views.profile.stats.answered_questions')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('StatsItemSimple',{attrs:{"value":_vm.user.stats.submitted_questions,"color":"accent lighten-3","description":_vm.$t('views.profile.stats.submitted_questions')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('StatsItemSimple',{attrs:{"value":_vm.user.stats.finished_journeys,"color":"accent lighten-3","description":_vm.$t('views.profile.stats.finished_journeys')}})],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-text',{class:{
                      'd-flex align-center justify-space-between':
                        _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('v-progress-circular',{attrs:{"size":"96","value":_vm.user.stats.duel_win_percent,"color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.user.stats.duel_win_percent)+" %")])]),_c('h5',{staticClass:"overline primary--text mt-2"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.stats.won_duels"))+" ")])],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-card',{attrs:{"color":"white","elevation":"0"}},[_c('v-card-text',{class:{
                      'd-flex align-center justify-space-between':
                        _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('v-progress-circular',{attrs:{"size":"96","value":_vm.user.stats.correct_questions_percent,"color":"success"}},[_c('span',[_vm._v(_vm._s(_vm.user.stats.correct_questions_percent)+"%")])]),_c('h5',{staticClass:"overline success--text mt-2"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.stats.correct_answers"))+" ")])],1)],1)],1)],1)],1)],1):_vm._e(),(!_vm.isPrivate || _vm.isMyProfile)?_c('section',[_c('v-container',[_c('h5',{staticClass:"primary--text overline font-weight bold text-center mb-2"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.subnavigation.achievements"))+" ")]),_c('v-row',{staticClass:"justify-center py-1",attrs:{"dense":""}},_vm._l((_vm.user.achievements),function(achievement,i){return _c('v-col',{key:i,staticClass:"text-center",attrs:{"cols":"2","sm":"1"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary","small":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"content":achievement.current_stage.stage,"overlap":"","bordered":"","offset-x":"10"}},[_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"secondary darken-3","size":"32"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":achievement.current_stage.image,"alt":achievement.title}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(achievement.subtitle)+" - "+_vm._s(achievement.title))])])],1)}),1)],1)],1):_vm._e(),(!_vm.isMyProfile)?_c('section',{staticClass:"primary pb-6 mt-2",attrs:{"id":"duels"}},[_c('v-container',[_c('h5',{staticClass:"text--subtitle white--text mb-2"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.friend_last_duels"))+" ")]),(_vm.user.last_duels.length == 0)?_c('v-sheet',{staticClass:"mt-2",attrs:{"width":"70%","color":"transparent"}},[_c('span',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(
                  _vm.$t('views.profile.invite_friend_question', {
                    name: _vm.user.name,
                  })
                )}}),_c('div',{staticClass:"mt-4"},[_c('v-tooltip',{attrs:{"color":"white","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.user.open_duel ||
                          _vm.user.open_duel_invitation ||
                          !_vm.user.can_duel
                            ? true
                            : false},on:{"click":function($event){return _vm.sendGameInvitation()}}},[_vm._v(" "+_vm._s(_vm.$t("resources.friends.invite_to_duel"))+" ")])],1)]}}],null,false,538659224)},[(_vm.user.can_duel && !_vm.user.is_bot)?_c('span',{staticClass:"text--text"},[_vm._v(" "+_vm._s(_vm.user.open_duel || _vm.user.open_duel_invitation ? _vm.$t("views.profile.open_game") : _vm.$t("resources.friends.invite_to_duel"))+" ")]):_vm._e(),(!_vm.user.can_duel && !_vm.user.is_bot)?_c('span',{staticClass:"text--text"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.no_duel_possible"))+" ")]):_vm._e(),(_vm.user.is_bot)?_c('span',{staticClass:"text--text"},[_vm._v(" "+_vm._s(_vm.$t("views.profile.no_invitation_possible"))+" ")]):_vm._e()])],1)]):_vm._e(),(_vm.user.last_duels.length > 0)?_c('div',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.user.last_duels),function(game,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"white","width":_vm.$vuetify.breakpoint.xs ? '100%' : '80%',"tile":"","elevation":"0","to":{
                      name: 'ResultsQuiz',
                      params: { quiz_id: game.id },
                    }}},[_c('v-card-text',{staticClass:"d-flex align-center pa-2",class:{
                        'flex-column text-center': _vm.$vuetify.breakpoint.xsOnly,
                      }},[_c('UserAvatar',{attrs:{"user-id":_vm.user.id,"image":_vm.user.avatar_img,"size":"48","disable-link":""}}),_c('div',{staticClass:"d-flex flex-column ml-2"},[_c('span',{staticClass:"overline pb-0"},[_vm._v(_vm._s(_vm.user.name))]),_c('span',{staticClass:"text--caption mt-0",class:_vm.myScore(game) > _vm.opponentScore(game)
                              ? 'success--text'
                              : 'error--text'},[_vm._v(_vm._s(_vm.myScore(game) > _vm.opponentScore(game) ? _vm.$t("views.yourGames.youWon") : _vm.$t("views.yourGames.youLost")))])]),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-center align-center",class:{
                          'mb-2': _vm.$vuetify.breakpoint.xsOnly,
                          'mr-4': _vm.$vuetify.breakpoint.smAndUp,
                        }},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.myScore(game))+" : "+_vm._s(_vm.opponentScore(game)))])])],1)],1)],1)}),1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"color":"primary","right":_vm.$vuetify.breakpoint.smAndUp,"top":_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('v-btn',{attrs:{"small":"","color":"success","depressed":"","disabled":_vm.user.open_duel ||
                            _vm.user.open_duel_invitation ||
                            !_vm.user.can_duel
                              ? true
                              : false},on:{"click":function($event){return _vm.sendGameInvitation()}}},[_vm._v(" "+_vm._s(_vm.$t("resources.friends.invite_to_duel"))+" ")])],1)]}}],null,false,3144738953)},[(_vm.user.can_duel && !_vm.user.is_bot)?_c('span',[_vm._v(" "+_vm._s(_vm.user.open_duel || _vm.user.open_duel_invitation ? _vm.$t("views.profile.open_game") : _vm.$t("resources.friends.invite_to_duel"))+" ")]):_vm._e(),(!_vm.user.can_duel && !_vm.user.is_bot)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("views.profile.no_duel_possible"))+" ")]):_vm._e(),(_vm.user.is_bot)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("views.profile.no_invitation_possible"))+" ")]):_vm._e()])],1)],1)],1):_vm._e()],1)],1):_vm._e()]):_vm._e()],1)],1):_vm._e(),(_vm.showRemoveFriendshipDialog)?_c('RemoveFriendshipDialog',{attrs:{"visible":_vm.showRemoveFriendshipDialog,"user":_vm.user},on:{"close":_vm.hideRemoveFriendshipDialog}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }