import API from "@/plugins/servers/Api.js";
import MediaAPI from "@/plugins/servers/MediaApi.js";

export const upload_file = async (_, payload) => {
  try {
    let [res] = await API.post("/media/upload", null, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_image = async (_, payload) => {
  try {
    let res = await MediaAPI.get(`${payload.url}`);
    return res;
  } catch (err) {
    return err;
  }
};
