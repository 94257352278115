export const get_quiz = (state) => state.quiz;
export const get_quiz_feedback = (state) => state.quiz_feedback;

export const get_quiz_result = (state) => state.quiz_result;
export const get_quiz_results = (state) => state.quiz_results;
export const get_quiz_result_answer = (state) => state.quiz_result_answer;
export const get_quiz_open_games = (state) => state.quiz_open_games;

export const get_quiz_categories = (state) => state.quiz_categories;

export const get_quiz_opponent = (state) => state.quiz_opponent;
export const get_quiz_info = (state) => state.quiz_info;

export const get_quiz_opponents = (state) => state.quiz_opponents;
