import API from "@/plugins/servers/Api.js";
import { Worksheet } from "@/models/worksheets/Worksheet";
import { WorksheetRound } from "@/models/worksheets/WorksheetRound";

export const fetch_worksheets = async ({ commit }) => {
  try {
    let [res] = await API.get("/quizzes/");

    if (res._status === 200) {
      let worksheets = [];
      res.quizzes.forEach((quiz) => {
        if (quiz.quiz_type === "worksheet") {
          worksheets.push(new Worksheet(quiz));
        }
      });
      commit("set_worksheets", worksheets);
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_worksheet = async ({ commit }, id) => {
  try {
    let [res] = await API.post(`/quizzes/${id}/play`);
    if (res._status === 200) {
      let rounds = [];
      let user = res.quiz.turn_id;
      res.rounds.forEach((round) => {
        rounds.push(new WorksheetRound(round, user));
      });
      commit("set_worksheet", new Worksheet(res.quiz));
      commit("set_worksheet_rounds", rounds);
    } else return null;
    return res;
  } catch (err) {
    return err;
  }
};
