<template>
  <div>
    <v-bottom-navigation
      app
      :input-value="nav.visible"
      :value="activeBtn"
      fixed
      class="secondary--text elevation-16"
      :class="{ 'higher-z-index elevation-0': !raiseZindex }"
      style="z-index: 10"
      grow
      :height="$vuetify.breakpoint.lgAndUp ? 72 : 56"
      :shift="false"
      :color="menu.visible ? 'white' : 'primary'"
      :dark="menu.visible"
      :background-color="menu.visible ? 'primary' : 'white'"
    >
      <v-btn v-show="!menu.visible" :to="{ name: 'Dashboard' }" active-class="">
        <span class="caption text--text mt-1">
          {{ $t("dashboard.tabs.dashboard") }}
        </span>
        <PIcon icon="dashboard" />
      </v-btn>

      <v-btn
        v-show="!menu.visible"
        :to="{ name: 'ExploreOverview' }"
        active-class=""
      >
        <span class="caption text--text mt-1">
          {{ exploreCustomName ? $t("ng.wiki") : $t("ng.explore") }}
        </span>
        <PIcon icon="explore" />
      </v-btn>

      <v-btn
        v-show="!menu.visible"
        :to="{ name: 'PlayOverview' }"
        active-class=""
      >
        <span class="caption text--text mt-1">
          {{ $t("views.selectCategory.play") }}
        </span>
        <PIcon icon="play" />
      </v-btn>

      <v-btn v-show="!menu.visible" :to="{ name: 'Ranking' }" active-class="">
        <span class="caption text--text mt-1">
          {{ $t("views.ranking.local") }}
        </span>
        <PIcon icon="community" />
      </v-btn>

      <v-btn v-show="!menu.visible" active-class="" exact @click="openMenu()">
        <span class="caption text--text mt-1">
          {{ $t("navBar.menu") }}
        </span>
        <PIcon icon="menu" />
      </v-btn>

      <v-btn v-show="menu.visible" active-class="" @click="logoutUser()">
        <span class="caption mt-1">
          {{ $t("views.menu.logout") }}
        </span>
        <PIcon icon="logout" color="white" />
      </v-btn>

      <FeedbackDialog
        v-if="menu.visible"
        with-icon
        icon="mdi-star"
        color="white"
      />

      <SupportDialog
        v-if="menu.visible"
        with-icon
        icon="mdi-face-agent"
        color="white"
      />

      <v-btn
        v-show="menu.visible"
        :to="{ name: 'Settings' }"
        active-class="white--text"
        @click="closeMenu()"
      >
        <span class="caption mt-1">
          {{ $t("views.menu.settings") }}
        </span>
        <PIcon icon="settings" color="white" />
      </v-btn>

      <v-btn v-show="menu.visible" active-class="" exact @click="closeMenu()">
        <span class="caption mt-1">
          {{ $t("navBar.menu") }}
        </span>
        <PIcon icon="close" color="white" />
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FeedbackDialog from "@/components/_app/dialogs/FeedbackDialog";
import SupportDialog from "@/components/_app/dialogs/SupportDialog";

export default {
  name: "BottomNavigation",
  components: {
    FeedbackDialog,
    SupportDialog,
  },
  data() {
    return {
      activeBtn: 1,
      raiseZindex: true,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    ...mapGetters("app", {
      nav: "get_bottomnavigation_settings",
      menu: "get_menu",
    }),
    exploreCustomName() {
      return process.env.VUE_APP_EXPLORE_CUSTOM_NAME === "true";
    },
  },
  watch: {
    menu: {
      handler: function (val) {
        this.raiseZindex = !val.visible;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapMutations("app", ["set_toggle_menu"]),
    openMenu() {
      this.activeBtn = 0;
      this.set_toggle_menu();
    },
    closeMenu() {
      this.activeBtn = 0;
      this.set_toggle_menu();
    },
    async logoutUser() {
      this.set_toggle_menu();
      let payload = {
        userId: this.currentUser.id,
        api_token: this.currentUser.apiToken,
      };
      let res = await this.logout(payload);
      if (res) this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.higher-z-index {
  z-index: 9999 !important;
}
</style>
