<template>
  <h3
    class="overline font-weight-bold d-flex align-center"
    :class="customClass"
  >
    <span>{{ text }}</span>
  </h3>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    text: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: "accent darken-1",
    },
  },
};
</script>

<style></style>
