export const get_avatars = (state) => state.avatars;

export const get_friends = (state) => state.friends;

export const get_friendship_invitations = (state) =>
  state.friendship_invitations;

export const get_user = (state) => state.user;

export const get_dashboard_data = (state) => state.dashboard;
export const get_dashboard_stats = (state) => state.dashboard.stats;
export const get_dashboard_ranking = (state) => state.dashboard.ranking;
export const get_dashboard_journeys = (state) => state.dashboard.journeys;
export const get_dashboard_quest = (state) => state.dashboard.quest;
export const get_dashboard_rewards = (state) => state.dashboard.rewards;
export const get_dashboard_nuggets = (state) => state.dashboard.nuggets;
export const get_dashboard_quizzes = (state) => state.dashboard.quizzes;
export const get_dashboard_friend_invitations = (state) =>
  state.dashboard.friend_invitations;
export const get_dashboard_content = (state) => state.dashboard.content;
/* export const get_dashboard_quiz_invitations = (state) => state.dashboard.quizzes; */
