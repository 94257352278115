import API from "@/plugins/servers/Api.js";

export const fetch_settings = async ({ commit }, payload) => {
  try {
    let [res] = await API.get(`/communities/init?language=${payload.language}`);
    if (res._status === 200) {
      let settings = { ...res.settings };
      settings.logo_large = res.logo_large_img_url;
      settings.logo_small = res.logo_small_img_url;
      settings.instance_name = res.name;
      commit("set_settings", settings);

      let register_info = {
        register_title: res.join_title,
        register_code: res.welcome_text,
        register_intro: res.join_text,
        register_addition: res.join_additional,
        register_hint: res.join_notice,
      };
      commit("set_register_info", register_info);
      commit("auth/set_auth_sources", res.auth_sources, { root: true });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_community = async ({ commit }, payload) => {
  try {
    let [res] = await API.get(`/communities/${payload.community_id}`);
    if (res._status === 200) commit("set_community", res.community);
    return res;
  } catch (err) {
    return null;
  }
};
