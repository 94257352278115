export const set_notifications = (state, notifications) => {
  state.notifications = notifications;
};

export const set_notification_count = (state, count) => {
  state.notification_count = count;
};

export const set_notification_count_seen = (state, count) => {
  state.notification_count_seen = count;
};
