/* eslint-disable no-mixed-spaces-and-tabs */
export class Report {
  id = -1;
  body = "";
  status = "";
  comment_flag = false;
  comments = null;
  user = null;
  created_at = "";
  question = null;

  constructor(obj) {
    this.id = obj.id;
    this.body = obj.body;
    this.status = obj.status;
    this.comment_flag = obj.comment_flag;
    this.comments = obj.comments;
    this.user = obj.user;
    this.created_at = new Date(obj.created_at);
    this.question = obj.question;
  }
}
