export const state = {
  avatars: null,
  friends: null,
  friendship_invitations: null,
  user: null,
  dashboard: {
    duels: [],
    journeys: [],
    nuggets: [],
    quest: null,
    ranking: null,
    stats: null,
    friend_invitations: [],
    content: null,
  },
};
